// @flow

import Cookies from 'js-cookie';
import type { ShippingQuote } from '../types';

export const FETCH_BID_QUOTE = 'FETCH_BID_QUOTE';
export type FetchBidQuoteAction = {
  type: 'FETCH_BID_QUOTE',
};

export const FETCH_BID_QUOTE_SUCCESS = 'FETCH_BID_QUOTE_SUCCESS';
export type FetchBidQuoteSuccessAction = {
  type: 'FETCH_BID_QUOTE_SUCCESS',
  shippingQuote: ShippingQuote,
};

export const FETCH_BID_QUOTE_FAILURE = 'FETCH_BID_QUOTE_FAILURE';
export type FetchBidQuoteFailureAction = {
  type: 'FETCH_BID_QUOTE_FAILURE',
  error: string,
};

export const RESET_BID_QUOTE = 'RESET_BID_QUOTE';
export type ResetBidQuoteAction = {
  type: 'RESET_BID_QUOTE',
};

export type Actions = (
  | FetchBidQuoteAction
  | FetchBidQuoteSuccessAction
  | FetchBidQuoteFailureAction
  | ResetBidQuoteAction
);

export const fetchQuote = ({ itemId, zip }: { itemId: string, zip: string }): Thunk<void> => {
  return async (dispatch) => {
    dispatch({
      type: FETCH_BID_QUOTE,
    });

    try {
      const response = await fetch(`/api/v1/items/${itemId}/shipping_quotes?to=${zip}`);
      const json = await response.json();

      if (response.status === 200) {
        Cookies.set('user_zip_code', zip, { expires: 365 });
        dispatch({
          type: FETCH_BID_QUOTE_SUCCESS,
          shippingQuote: {
            id: json.id,
            quote: json.formatted_total,
            zip: json.zip,
          },
        });
      } else if (response.status === 400 && json.error) {
        dispatch({
          type: FETCH_BID_QUOTE_FAILURE,
          error: json.error,
        });
      } else {
        dispatch({
          type: FETCH_BID_QUOTE_FAILURE,
          error: 'Could not retrieve shipping quote',
        });
      }
    } catch (e) {
      console.error(e);
      dispatch({
        type: FETCH_BID_QUOTE_FAILURE,
        error: 'Could not retrieve shipping quote',
      });
    }
  };
};

export const resetQuote = () => {
  return {
    type: 'RESET_BID_QUOTE',
  };
};
