import React, { Component } from 'react';
import PropTypes from 'prop-types';

import PaymentMethodCreditCardForm from '~/public/shared/components/PaymentMethodCreditCardForm';
import PaymentMethodPlaidForm from '~/public/shared/components/PaymentMethodPlaidForm/PaymentMethodPlaidForm';
import PaymentMethodGiftCardForm from '~/public/shared/components/PaymentMethodGiftCardForm';
import RadioGroupField from '~/public/shared/components/RadioGroupField';

class PaymentMethodFields extends Component {
  renderPaymentMethodTypeSelector = () => {
    const { paymentMethodType, paymentMethodTypes } = this.props;

    if (paymentMethodTypes.length > 1) {
      return (
        <RadioGroupField
          name="payment_type"
          className="u-mb4"
          value={paymentMethodType.value}
          options={paymentMethodTypes}
          onChange={this.props.onPaymentMethodTypeChange}
        />
      );
    } else {
      return null;
    }
  }

  renderPaymentMethodFields = () => {
    const {
      onPaymentMethodChange,
      addresses,
      isSaving,
      paymentMethod,
      paymentMethodType,
      paypageId,
      paypageUrl,
      plaidEnvironment,
      plaidPublicKey,
      useVantiv,
    } = this.props;

    switch (paymentMethodType.value) {
      case 'plaid':
        return (
          <PaymentMethodPlaidForm
            onPlaidSuccess={this.props.onPlaidSuccess}
            isSaving={isSaving}
            paymentMethod={paymentMethod}
            plaidEnvironment={plaidEnvironment}
            plaidPublicKey={plaidPublicKey}
          />
        );
      case 'giftCard':
        return (
          <PaymentMethodGiftCardForm
            onPaymentMethodChange={onPaymentMethodChange}
            paymentMethod={paymentMethod}
            isSaving={isSaving}
          />
        );
      default:
        return (
          <PaymentMethodCreditCardForm
            onPaymentMethodChange={onPaymentMethodChange}
            addresses={addresses}
            paymentMethod={paymentMethod}
            useVantiv={useVantiv}
            paypageId={paypageId}
            paypageUrl={paypageUrl}
          />
        );
    }
  }

  render() {
    return (
      <div>
        {this.renderPaymentMethodTypeSelector()}
        {this.renderPaymentMethodFields()}
      </div>
    );
  }
}

PaymentMethodFields.propTypes = {
  onPaymentMethodChange: PropTypes.func.isRequired,
  onPaymentMethodTypeChange: PropTypes.func.isRequired,
  onPlaidSuccess: PropTypes.func.isRequired,

  paymentMethod: PropTypes.object.isRequired,
  paymentMethodType: PropTypes.object.isRequired,
  paymentMethodTypes: PropTypes.array.isRequired,

  addresses: PropTypes.array.isRequired,
  isSaving: PropTypes.bool.isRequired,
  paypageId: PropTypes.string.isRequired,
  paypageUrl: PropTypes.string.isRequired,
  plaidEnvironment: PropTypes.string.isRequired,
  plaidPublicKey: PropTypes.string.isRequired,
  useVantiv: PropTypes.bool.isRequired,
};

PaymentMethodFields.defaultProps = {
  addresses: [],
};

export default PaymentMethodFields;
