/* globals Plaid */

class PlaidHelper {
  constructor({ environment, publicKey, onSuccess }) {
    this.environment = environment;
    this.publicKey = publicKey;

    this.plaidHandler = Plaid.create({
      clientName: 'EBTH',
      env: environment,
      key: publicKey,
      product: ['auth'],
      selectAccount: true,
      onSuccess: onSuccess,
    });
  }

  openPlaidModal(institutionId) {
    this.plaidHandler.open(institutionId);
  }

  search(query) {
    return fetch(
      `https://${this.environment}.plaid.com/institutions/search`,
      {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          query: query,
          products: ['auth'],
          public_key: this.publicKey,
        }),
      }
    );
  }
}

export default PlaidHelper;
