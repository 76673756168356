// @flow

import React, { Component } from 'react';
import Cookies from 'js-cookie';
import { connect } from 'react-redux';
import { Button, LoadingDots, Icon } from '~/public/shared/components';

import type { FulfillmentType, Item, ShippingQuote, User, Address } from '../../types';
import Dropdown, { DropdownToggle, DropdownContent } from '../../../dashboard/components/Dropdown';

type Props = {
  item: Item,
  shippingQuote: ?ShippingQuote,
  error: ?string,
  inFlight: boolean,
  fetchQuote: ({ zip?: string, addressId?: string, itemId: string }) => void,
  resetQuote: () => void,
  fulfillmentType: FulfillmentType,
  user: ?User,
  internationalShippingQuotesEnabled: boolean,
};

type State = {
  zip: string,
  manuallySubmitted: boolean,
  selectedAddress: ?Address,
}

class Shipping extends Component<Props, State> {
  state = {
    zip: Cookies.get('user_zip_code') || '',
    manuallySubmitted: false,
    selectedAddress: null,
  }

  componentDidMount() {
    if (this.internationalUser && this.props.user && this.props.user.addresses) {
      const { addresses } = this.props.user;
      this.setState({
        selectedAddress: addresses.find((address) => address.isPrimary) || addresses[0],
      });
    }
  }

  get internationalUser() {
    const {
      user,
      internationalShippingQuotesEnabled,
    } = this.props;
    return internationalShippingQuotesEnabled && user && user.internationalUser && !user.internationalIneligible;
  }
  // Disable Auto Quoting on Form
  // componentWillMount() {
  //   const { item, fetchQuote } = this.props;
  //   const { zip } = this.state;

  //   if (zip.length) {
  //     fetchQuote({ zip, itemId: item.id });
  //   }
  // }

  zipInput: ?HTMLInputElement;

  handleZipChange = (e: SyntheticInputEvent<EventTarget>) => {
    this.setState({ zip: e.target.value });
  }

  handleKeyDown = (e: SyntheticKeyboardEvent<HTMLInputElement>) => {
    // Handle "Enter" key since we can't nest this as a form w/in a form
    if (e.keyCode === 13) {
      this.handleFormSubmit();
    }
  }

  handleFormSubmit = () => {
    const { item, fetchQuote } = this.props;
    const { zip, selectedAddress } = this.state;
    this.setState({
      manuallySubmitted: true,
    });
    if (this.internationalUser && selectedAddress) {
      fetchQuote({ addressId: selectedAddress.id, itemId: item.id });
    } else {
      fetchQuote({ zip: zip, itemId: item.id });
    }
  }

  handleEditZipQuoteBtn = (e: SyntheticEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    this.props.resetQuote();
    this.focusZipInput();
  }

  focusZipInput = () => {
    // timeout required to wait for input to be rendered in DOM
    setTimeout(() => {
      if (this.zipInput) {
        this.zipInput.focus();
      }
    }, 0);
  }

  handleZipInputFocus = (e: SyntheticInputEvent<HTMLInputElement>): void => {
    e.target.select();
  }

  renderAdditionalShippingAndHandlingCharges = () => {
    return (
      <div className="bid-fulfillment-options__additional-shipping-handling">
        Items won for over $5,000 may be assessed an additional amount for shipping and handling
      </div>
    );
  }


  renderZipQuoting = () => {
    const { error, inFlight, item } = this.props;
    const shouldRenderAdditionalShippingAndHandlingCharges =
      item.highBidAmount >= 3000 || (item.typeName === 'buy_now' && item.minimumBidAmount >= 5000);
    return (
      <div className="bid-fulfillment-options__flex-container">
        <div className="bid-fulfillment-options__ship-form">
          {this.renderInput()}
          <Button
            name="button"
            onClick={this.handleFormSubmit}
            buttonStyle="secondary"
            buttonSize="extra-sm"
            className="qa-fetch-shipping-quote"
            inFlight={inFlight}
          >
            Get Estimate
          </Button>
        </div>
        {shouldRenderAdditionalShippingAndHandlingCharges && this.renderAdditionalShippingAndHandlingCharges()}
        {error &&
          <div className="form-group__error qa-shipping-quote-error">
            {error}
          </div>
        }
      </div>
    );
  }

  renderInput = () => {
    const { user, inFlight } = this.props;
    const { selectedAddress } = this.state;
    const addressDescription = (address: Address) => `${address.line1}, ${address.city}`;
    if (this.internationalUser && selectedAddress && user && user.addresses) {
      const { addresses } = user;
      return (
        <Dropdown className="bid-fulfillment-options__ship-form-dropdown">
          <DropdownToggle>
            <Button buttonStyle="text-navigation" className="u-px1">
              <span>{addressDescription(selectedAddress)}</span>
              <Icon icon="chevron-down" className="btn__icon btn__icon--right u-ml0" />
            </Button>
          </DropdownToggle>
          <DropdownContent>
            <ul className="dropdown__list">
              {
                addresses.map((address) => {
                  return (
                    <li key={address.id}>
                      <div
                        onClick={() => {
                          this.setState({
                            selectedAddress: address,
                          });
                        }}
                        className="btn btn--text-navigation dropdown__btn"
                      >
                        {addressDescription(address)}
                      </div>
                    </li>
                  );
                })
              }
            </ul>
          </DropdownContent>
        </Dropdown>
      );
    } else {
      return (
        <input
          type="tel"
          name="zip"
          value={this.state.zip}
          ref={(ref) => { this.zipInput = ref; }}
          onFocus={this.handleZipInputFocus}
          onChange={this.handleZipChange}
          onKeyDown={this.handleKeyDown}
          placeholder="ZIP code"
          className="input bid-fulfillment-options__ship-form-input qa-shipping-quote-zip"
          required
          title="Shipping ZIP Code"
          disabled={inFlight}
        />
      );
    }
  }

  renderDetails = () => {
    const { shippingQuote, inFlight } = this.props;
    const { manuallySubmitted } = this.state;

    if (shippingQuote) {
      return (
        <div key="shipping_quote">
          Deliver to <strong>{shippingQuote.zip}</strong>&ensp;
          <Button
            onClick={this.handleEditZipQuoteBtn}
            buttonStyle="secondary"
            buttonSize="extra-sm"
            className="qa-edit-shipping-quote-zip"
          >
            Edit Zip
          </Button>
        </div>
      );
    } else if (inFlight && !manuallySubmitted) {
      return <LoadingDots />;
    } else {
      return this.renderZipQuoting();
    }
  }

  render() {
    const {
      fulfillmentType: {
        label,
      },
      shippingQuote,
    } = this.props;

    return (
      <tr className="bid-fulfillment-options__option">
        <td className="bid-fulfillment-options__option-cell">
          <div className="bid-fulfillment-options__option-label">
            {label}
            <div className="bid-fulfillment-options__option-cost qa-shipping-quote-price">
              {shippingQuote ? shippingQuote.quote : '\u00A0'}
            </div>
          </div>
        </td>
        <td className="bid-fulfillment-options__option-cell">
          <div className="bid-fulfillment-options__shipping-details">
            {this.renderDetails()}
          </div>
        </td>
      </tr>
    );
  }
}

const mapStateToProps = (
  {
    user: { user },
  }
) => ({
  user,
});

export default connect(mapStateToProps)(Shipping);
