import update from 'immutability-helper';
import React, { Component } from 'react';
import qs from 'query-string';
import PropTypes from 'prop-types';

import PaymentMethodHelper from '~/public/shared/utils/PaymentMethodHelper';
import PaymentMethodFields from '~/public/shared/components/PaymentMethodFields';
import getStripeToken from '~/public/shared/utils/GetStripeToken';

import refreshPage from '~/utils/refreshPage';
import { Button } from '~/public/shared/components';

class AddPaymentMethodForm extends Component {
  constructor(props, ...args) {
    super(props, ...args);

    const paymentMethodTypes = PaymentMethodHelper.paymentMethodTypes();
    this.paymentMethodTypes = paymentMethodTypes;

    this.state = {
      isSaving: false,
      paymentMethodType: paymentMethodTypes[0],
      paymentMethod: PaymentMethodHelper.initialize(props.addresses),
    };
  }

  componentDidMount() {
    // grabs the query string in the url
    const query = qs.parse(window.location.search);
    // sets the paymentMethodType if query method is 0 or 1 (matching index of paymentMethodTypes)
    if (query.method && (query.method === 0 || 1)) {
      this.setState({
        paymentMethodType: this.paymentMethodTypes[query.method],
      });
    }
  }

  onPlaidSuccess = async (publicToken, metadata) => {
    this.setState({ isSaving: true });

    const { isSuccess, paymentMethod } = await PaymentMethodHelper.savePlaid(
      this.state.paymentMethod,
      publicToken,
      metadata
    );

    if (isSuccess) {
      window.analytics.track('Payment Method Added', {
        payment_method_id: paymentMethod.id.value,
        payment_method_type: 'echeck',
        was_marked_primary: false,
      });
      refreshPage();
    } else {
      this.setState({
        paymentMethod,
        isSaving: false,
      });
    }
  };

  handleSubmit = async (event) => {
    const { stripe, elements } = this.props;
    event.preventDefault();
    this.setState({ isSaving: true });

    let { paymentMethod } = this.state;

    // clear out any errors
    paymentMethod = update(paymentMethod, {
      hasErrors: { $set: false },
      base: { errors: { $set: [] } },
    });

    paymentMethod = PaymentMethodHelper.validate(paymentMethod);
    if (paymentMethod.hasErrors) {
      this.setState({ paymentMethod, isSaving: false });
      return;
    }

    // BEGIN - GIFT CARD
    if (paymentMethod.paymentMethodTypeId && paymentMethod.paymentMethodTypeId === 4) {
      paymentMethod = await PaymentMethodHelper.redeemGiftCard(paymentMethod);
      if (paymentMethod.hasErrors) {
        this.setState({ paymentMethod, isSaving: false });
        window.EBTH.flash.show(paymentMethod.base.errors[0], 'error');
      } else {
        refreshPage();
      }
      return;
    }
    // END - GIFT CARD

    // now using Stripe Elements for PCI compliance
    paymentMethod = await getStripeToken(paymentMethod, stripe, elements);

    if (paymentMethod.hasErrors) {
      this.setState({ paymentMethod, isSaving: false });
      return;
    } else {
      // re-render so that we clear errors
      this.setState({ paymentMethod });
    }

    paymentMethod = await PaymentMethodHelper.savePaymentMethod(paymentMethod);
    if (paymentMethod.hasErrors) {
      this.setState({ paymentMethod, isSaving: false });
    } else {
      // - Reload if we were successful in saving to view the new list of payment methods.
      // - Reload if we got a 401 (Unauthorized) response, to show the sign in screen that
      //   will send the user back to payment methods after they authenticate
      if (window.analytics) {
        window.analytics.track('Payment Method Added', {
          payment_method_id: paymentMethod.id.value,
          payment_method_type: 'credit card',
          was_marked_primary: paymentMethod.primary.value,
        });
        if (paymentMethod.address.isAddingAddress) {
          window.analytics.track('Address Added', {
            address_id: paymentMethod.address.id.value,
            context: 'payment_methods_page',
            was_marked_primary: false, // User can't set address as primary in this context
          });
        }
      }
      refreshPage();
    }
  };

  handlePaymentMethodChange = (paymentMethod) => {
    this.setState({ paymentMethod });
  };

  handlePaymentMethodTypeChange = (value) => {
    const { paymentMethod } = this.state;
    const newPaymentMethod = update(paymentMethod, {
      base: { errors: { $set: [] } },
    });

    this.setState({
      paymentMethodType: PaymentMethodHelper.PAYMENT_METHOD_TYPES[value],
      paymentMethod: newPaymentMethod,
    });
  };

  render() {
    const { isSaving, paymentMethod, paymentMethodType } = this.state;
    const {
      addresses,
      paypageId,
      paypageUrl,
      plaidEnvironment,
      plaidPublicKey,
      useVantiv,
    } = this.props;

    return (
      <form
        className="u-mb4"
        onSubmit={this.handleSubmit}
        ref={(paymentForm) => {
          this.paymentForm = paymentForm;
        }}
      >
        <PaymentMethodFields
          onPlaidSuccess={this.onPlaidSuccess}
          addresses={addresses}
          isSaving={isSaving}
          paymentMethod={paymentMethod}
          onPaymentMethodChange={this.handlePaymentMethodChange}
          paymentMethodType={paymentMethodType}
          onPaymentMethodTypeChange={this.handlePaymentMethodTypeChange}
          paymentMethodTypes={this.paymentMethodTypes}
          paypageId={paypageId}
          paypageUrl={paypageUrl}
          plaidEnvironment={plaidEnvironment}
          plaidPublicKey={plaidPublicKey}
          useVantiv={useVantiv}
        />

        {paymentMethodType.showSubmitButton && (
          <Button type="submit" buttonStyle="primary" inFlight={isSaving}>
            { paymentMethodType.submitButtonText }
          </Button>
        )}
      </form>
    );
  }
}

AddPaymentMethodForm.propTypes = {
  addresses: PropTypes.array,
  paypageId: PropTypes.string.isRequired,
  paypageUrl: PropTypes.string.isRequired,
  plaidEnvironment: PropTypes.string.isRequired,
  plaidPublicKey: PropTypes.string.isRequired,
  useVantiv: PropTypes.bool.isRequired,
};

AddPaymentMethodForm.defaultProps = {
  addresses: [],
};

export default AddPaymentMethodForm;
