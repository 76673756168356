// @flow

import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment';

import BidStatus from '../components/BidStatus';
import BidButton from '../components/BidButton';
import ModalBidding from '../components/ModalBidding';
import { TimeRemaining } from '~/public/shared/components';
import * as ModalActions from '../actions/modal';

import type { Item } from '../types';

type Props = {
  item: Item,
  openModal: (string) => void,
  paypageId: string,
  paypageUrl: string,
  plaidEnvironment: string,
  plaidPublicKey: string,
  useVantiv: boolean,
};

class ItemDetailContainer extends Component<Props> {
  handleOpenFulfillmentInfoModal = (e: SyntheticEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    this.props.openModal('deliveryOptions');
  }

  render() {
    const { item } = this.props;
    const { extended, saleEndsAt, aasmState } = item;
    const saleEndsAtDate = saleEndsAt ? new Date(saleEndsAt) : null;
    const endedStates = ['sold', 'unsold', 'invoiced', 'paid'];
    const ended = endedStates.includes(aasmState);
    const linkText = item.typeName === 'buy_now' ? 'Buy Now' : 'bidding';
    const linkUrl = item.typeName === 'buy_now' ? 'Buy-Now-Program' : 'How-do-I-bid';
    return (
      <Fragment>
        <div className="item-detail__time">
          <TimeRemaining
            extended={extended}
            endsAt={saleEndsAtDate}
            aasmState={aasmState}
            getReferenceTime={window.EBTH.timesync.now}
            className="item-detail__relative-time"
          />
          {saleEndsAt &&
            <div className="item-detail__end-datetime">
              {moment.parseZone(saleEndsAt).format('MMMM Do YYYY @ h:mma')} EST
            </div>
          }
        </div>
        <section aria-label="Bid Status and Bidding" className="item-detail__bid-status" itemType="http://schema.org/Offer" itemProp="offers" itemScope>
          <meta itemProp="priceCurrency" content="USD" />
          <meta itemProp="price" content={item.highBidAmount} />
          <BidStatus itemDetailContext />
          <BidButton className="item-detail__bid-status-group item-detail__bid-status-group--button" />
          <ModalBidding {...this.props} />
        </section>
        { !ended &&
          <div className="u-text-right u-mt1 u-flex-1">
            <a href={`https://support.ebth.com/s/article/${linkUrl}`} target="_blank noreferrer">
              <svg className="icon u-mr1" viewBox="0 0 24 24" width="18" height="18" role="img"><path d="M12,2.21428571 C6.57142857,2.21428571 2.21428571,6.57142857 2.21428571,12 C2.21428571,17.4285714 6.57142857,21.7857143 12,21.7857143 C17.4285714,21.7857143 21.7857143,17.4285714 21.7857143,12 C21.7857143,6.57142857 17.4285714,2.21428571 12,2.21428571 Z M11.9285714,4.92857143 C13,4.92857143 13.7142857,5.78571429 13.7142857,6.64285714 C13.7142857,7.5 13.0714286,8.35714286 11.9285714,8.35714286 C10.8571429,8.35714286 10.2142857,7.5 10.2142857,6.64285714 C10.2142857,5.78571429 10.8571429,4.92857143 11.9285714,4.92857143 Z M9.42857143,19.0714286 L9.5,18.7142857 L9.78571429,18.7142857 C10.3571429,18.6428571 10.5714286,18.6428571 10.5714286,18.3571429 L10.5714286,13.0714286 C10.5714286,11.7857143 10.4285714,11.6428571 9.71428571,11.4285714 L9.28571429,11.3571429 L9.28571429,11.0714286 L13.4285714,9.92857143 L13.4285714,18.4285714 C13.4285714,18.6428571 13.5714286,18.6428571 14.4285714,18.7857143 L14.6428571,18.7857143 L14.7142857,19.1428571 L9.42857143,19.1428571 L9.42857143,19.0714286 Z" />
              </svg>Learn about {linkText}
            </a>
          </div>
        }
      </Fragment>
    );
  }
}

const mapStateToProps = ({ item: { item } }) => ({ item });
const mapDispatchToProps = (dispatch) => (
  bindActionCreators({ ...ModalActions }, dispatch)
);

export default connect(mapStateToProps, mapDispatchToProps)(ItemDetailContainer);
