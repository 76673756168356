// @flow

import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import ReactModal from 'react-modal';
import URI from 'urijs';
import classNames from 'classnames';

import { Icon } from '~/public/shared/components';
import timeRemaining from '~/public/shared/utils/timeRemaining';
import CountUpCurrency from './CountUpCurrency';
import ModalBiddingContent from './ModalBiddingContent';
import ModalBidIncrements from './ModalBidIncrements';
import ModalLearnMore from './ModalLearnMore';
import * as ModalActions from '../actions/modal';

import type { ModalState, Item } from '../types';

type Props = {
  modal: ModalState,
  item: Item,
  closeModal: (string) => void,
  paypageId: string,
  paypageUrl: string,
  plaidEnvironment: string,
  plaidPublicKey: string,
  useVantiv: boolean,
};

type State = {
  interval: ?IntervalID,
  endsAt: moment,
}

class ModalBiddingModal extends Component<Props, State> {
  state = {
    // TODO: If item isn't ending any time soon interval could be much longer
    interval: setInterval(this.updateEndTime, 500),
    endsAt: this.props.item.saleEndsAt,
  }

  componentWillUnmount() {
    const { interval } = this.state;
    if (interval) {
      clearInterval(interval);
    }
  }

  updateEndTime = (): void => {
    this.setState({ endsAt: this.props.item.saleEndsAt });
  }

  renderTimeRemaining = () => {
    const now = moment(window.EBTH.timesync.now());
    const endDate = moment(this.state.endsAt);
    const diff = endDate.diff(now, 'days', true);
    const daysRemaining = Math.round(diff);

    if (Math.floor(diff) >= 1) {
      return (
        <Fragment>
          <span className="bid-modal__time-val">{daysRemaining} {daysRemaining > 1 ? 'days' : 'day'}</span>
        </Fragment>
      );
    } else {
      return (
        <Fragment>
          {timeRemaining({ from: now, to: endDate }).map(({ unit, value }) => (
            <Fragment key={unit}>
              <span className="bid-modal__time-val">{value}</span>
              <span className="bid-modal__time-unit">{unit[0]}</span>
            </Fragment>
          ))}
        </Fragment>
      );
    }
  }

  handleCloseBidModal = () => {
    if (this.props.item.aasmState === 'invoiced' && this.props.item.typeName === 'buy_now') {
      window.location.reload(false);
    }

    this.props.closeModal('bidModal');
  }

  render() {
    const { modal, item } = this.props;
    const { endsAt } = this.state;

    const buildThumbnailUrl = (url: ?string): ?string => {
      if (url != null) {
        return URI(url)
          .query('')
          .query({
            fit: 'crop',
            auto: 'format',
            w: 80,
            h: 80,
          })
          .toString();
      } else {
        return null;
      }
    };

    const now = moment(window.EBTH.timesync.now());
    const ended = now.isAfter(endsAt);

    const underFiveMins = moment(endsAt).diff(now, 'minutes', true) < 5;
    const timeValueClasses = classNames(
      'bid-modal__meta-value',
      {
        'bid-modal__meta-value--danger': underFiveMins,
      },
    );

    return (
      <ReactModal
        bodyOpenClassName="body-modal-open"
        htmlOpenClassName="html-modal-open"
        overlayClassName="modal modal--open"
        className="modal__body bid-modal qa-bid-modal"
        isOpen={modal.bidModal === 'open'}
        onRequestClose={this.handleCloseBidModal}
      >
        <div className="bid-modal__header">
          {
            item.typeName !== 'buy_now' && (
              <div className="bid-modal__header-img-frame">
                <img className="bid-modal__header-img" src={buildThumbnailUrl(item.mainImage)} alt="" />
              </div>
            )
          }
          <div className="bid-modal__header-meta">
            <div>
              <div className="bid-modal__meta-label">
                {
                  item.typeName === 'buy_now'
                    ? <div className="bid-modal-buy-now-header-text"> BUY NOW </div>
                    : `${ended ? 'Winning' : 'Current'} Bid`
                }
              </div>
              {
                item.typeName !== 'buy_now' && (
                  <div className="bid-modal__meta-value">
                    <strong>
                      <CountUpCurrency amount={item.typeName === 'reserve' && item.bidsCount === 0 ? item.minimumBidAmount : item.highBidAmount} />
                    </strong>
                  </div>
                )
              }
            </div>
            <div>
              <div className="bid-modal__meta-label">Time Left</div>
              <div className={timeValueClasses}>
                <Icon icon="clock" className="bid-modal__time-icon" />
                {ended ? 'Ended' : this.renderTimeRemaining()}
              </div>
            </div>
          </div>
          <button
            onClick={this.handleCloseBidModal}
            className="modal__close bid-modal__header-close-btn"
            aria-label="Close"
            type="button"
          >
            <Icon icon="close" size="40" />
          </button>
        </div>
        <div className="modal__content">
          {modal.bidModal === 'open' &&
            <ModalBiddingContent {...this.props} />
          }
        </div>
        {/*
          ModalBidIncrements included here (instead of in PanePlaceBid.js) to
          avoid problems caused by unmounting within another modal
        */}
        <ModalBidIncrements currentBid={item.highBidAmount} />
        <ModalLearnMore />
      </ReactModal>
    );
  }
}

const mapStateToProps = ({ modal, item: { item } }) => ({ modal, item });
const mapDispatchToProps = (dispatch) => (
  bindActionCreators({ ...ModalActions }, dispatch)
);

export default connect(mapStateToProps, mapDispatchToProps)(ModalBiddingModal);
