// @flow

import React, { Component } from 'react';

import { Button } from '~/public/shared/components';
import FulfillmentTypes from './FulfillmentTypes';

import type { Item, Bid, Config } from '../types';

type Props = {
  onBidChange: (Object) => void,
  bid: Bid,
  item: Item,
  changePane: (string) => void,
  config: Config,
};

class PaneSelectFulfillment extends Component<Props> {
  handleSubmitFulfillment = (e: SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (window.analytics) {
      const { item, bid } = this.props;

      if (bid.fulfillmentType) {
        const { type, price } = bid.fulfillmentType;

        window.analytics.track('Item Bid Fulfillment Selected', {
          item_id: item.id,
          fulfillment_type: type,
          fulfillment_cost: price,
        });
      }
    }

    this.props.changePane('bid');
  }

  render() {
    const {
      item,
      onBidChange,
      bid,
      changePane,
      config,
    } = this.props;

    const selectedFulfillmentType = bid.fulfillmentType;
    const unableToShipError = bid.fulfillmentErrors.length > 0 && selectedFulfillmentType && selectedFulfillmentType.type === 'shipping';
    return (
      <form onSubmit={this.handleSubmitFulfillment} noValidate>
        <h3 className="bid-modal__headline">How do you want to get this item if you win?</h3>
        <FulfillmentTypes
          onFulfillmentTypeChange={(ft) => onBidChange({ fulfillmentType: ft })}
          onShippingAddressChange={(id) => onBidChange({ address: { id: id } })}
          fulfillmentTypes={item.fulfillmentTypes}
          selectedfulfillmentType={selectedFulfillmentType}
          isPlacingBid
          changePane={changePane}
          config={config}
        />
        <Button
          type="submit"
          name="button"
          buttonDisplay="block"
          buttonStyle="primary-important"
          buttonSize="lg"
          className="u-mt2 qa-continue-fulfillment"
          disabled={selectedFulfillmentType == null || !!unableToShipError}
        >
          {unableToShipError ? 'Update Delivery Option' : 'Continue'}
        </Button>
      </form>
    );
  }
}

export default PaneSelectFulfillment;
