// @flow

import React, { Component } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';

import BidStatus from '../components/BidStatus';
import BidButton from '../components/BidButton';
import ModalBidding from '../components/ModalBidding';
import { TimeRemaining } from '~/public/shared/components';

import type { Item } from '../types';

type Props = {
  item: Item,
  paypageId: string,
  paypageUrl: string,
  plaidEnvironment: string,
  plaidPublicKey: string,
  useVantiv: boolean,
};

class FollowedItemContainer extends Component<Props> {
  render() {
    const { item } = this.props;
    const { extended, saleEndsAt, aasmState } = item;
    const saleEndsAtDate = saleEndsAt ? new Date(saleEndsAt) : null;

    return (
      <div className="clearfix">
        <div className="u-mb2">
          <TimeRemaining
            extended={extended}
            endsAt={saleEndsAtDate}
            aasmState={aasmState}
            getReferenceTime={window.EBTH.timesync.now}
          />
          {saleEndsAt &&
            <div className="u-text-muted">
              {moment.parseZone(saleEndsAt).format('MMMM Do YYYY @ h:mma')} EST
            </div>
          }
        </div>
        <BidStatus />
        <BidButton className="u-mt2" />
        <ModalBidding {...this.props} />
      </div>
    );
  }
}

const mapStateToProps = ({ item: { item } }) => ({ item });

export default connect(mapStateToProps, null)(FollowedItemContainer);
