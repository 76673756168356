/* globals analytics */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import update from 'immutability-helper';

import BuyerRegistrationFields from '~/public/buyer_registration_form/components/BuyerRegistrationFields';
import BuyerRegistrationHelper from '~/public/buyer_registration_form/utils/BuyerRegistrationHelper';
import getStripeToken from '~/public/shared/utils/GetStripeToken';
import refreshPage from '~/utils/refreshPage';
import { Button, Flash } from '~/public/shared/components';

class BuyerRegistrationForm extends Component {
  constructor(props, ...args) {
    super(props, ...args);

    this.state = {
      registration: BuyerRegistrationHelper.initialize({
        firstName: props.registration.firstName,
        lastName: props.registration.lastName,
        gender: props.registration.gender,
      }),
      isSaving: false,
    };
  }

  handleRegistrationChange = (registration) => {
    this.setState({ registration });
  }

  handleSubmit = async (e) => {
    e.preventDefault();

    this.setState({ isSaving: true });

    let { registration } = this.state;
    const { stripe, elements } = this.props;

    // clear out any errors
    registration.paymentMethod = update(registration.paymentMethod, {
      hasErrors: { $set: false },
      base: { errors: { $set: [] } },
    });

    registration = BuyerRegistrationHelper.validate(registration);

    if (registration.hasErrors === true) {
      this.setState({ registration, isSaving: false });
      return;
    }

    registration.paymentMethod = await getStripeToken(registration.paymentMethod, stripe, elements);

    if (registration.paymentMethod.hasErrors) {
      this.setState({ registration, isSaving: false });
      return;
    }

    registration = await BuyerRegistrationHelper.save(registration);
    if (registration.hasErrors) {
      this.setState({ registration, isSaving: false });
      return;
    }

    analytics.track('New User Sign Up', refreshPage);
  }

  renderBaseErrors() {
    const { registration } = this.state;
    const baseErrors = registration.base.errors;
    const paymentMethodBaseErrors = registration.paymentMethod.base.errors;
    const errors = baseErrors.concat(...paymentMethodBaseErrors);

    if (errors.length === 0) return null;

    return (
      <Flash
        flashStyle="error"
        showIcon
        className="u-mb2"
      >
        {errors.join(',')}
      </Flash>
    );
  }

  render() {
    const { registration, isSaving } = this.state;
    const { useVantiv } = this.props;

    return (
      <div>
        <h2 className="modal__content--buyer-registration-heading">A few more details and you’ll be ready to bid!</h2>
        <p>This information ensures that real people are placing legitimate bids.</p>

        {this.renderBaseErrors()}

        <form
          onSubmit={this.handleSubmit}
          ref={(paymentForm) => { this.paymentForm = paymentForm; }}
        >
          <BuyerRegistrationFields
            registration={registration}
            onChange={this.handleRegistrationChange}
            useVantiv={useVantiv}
          />

          <Button
            type="submit"
            name="button"
            buttonDisplay="block"
            buttonSize="xl"
            buttonStyle="primary-important"
            inFlight={isSaving}
            className="u-mt2"
          >
            Complete Registration
          </Button>
        </form>
      </div>
    );
  }
}

BuyerRegistrationForm.propTypes = {
  paypageId: PropTypes.string.isRequired,
  paypageUrl: PropTypes.string.isRequired,
  plaidEnvironment: PropTypes.string.isRequired,
  plaidPublicKey: PropTypes.string.isRequired,
  useVantiv: PropTypes.bool.isRequired,
  registration: PropTypes.object.isRequired,
};

BuyerRegistrationForm.defaultProps = {};

export default BuyerRegistrationForm;
